@import url(https://fonts.googleapis.com/css?family=Roboto:300&display=swap);

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 900px;
  background-color: #ff8b01ff;
  /* background-image: linear-gradient(360deg, rgba(255, 167, 45, 1) 0%, rgba(255,155,3,1) 40%, rgba(255,143,3,1) 100%);  */
  background-repeat: no-repeat;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 991px) {

    .Logo2 {
        justify-content: center;
        width: 6%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .MainContainer {
        width: auto;
        height: auto;
        height: 80%;
        padding: 3% 5%;
        margin: 2%;
        display: flex;
        background-color: white;
        border-radius: 6px;
        border: 1.5px solid rgb(66, 66, 66);
        flex-wrap: wrap;

    }

    .MainContainer2 {
        width: auto;
        height: auto;
        min-height: 650px;
        padding: 3% 5%;
        margin: 2%;
        background-color: white;
        border-radius: 6px;
        border: 1.5px solid rgb(66, 66, 66);

    }

    .MainContainerRandomProduct {
        width: auto;
        height: auto;
        min-height: 650px;
        padding: 3% 5%;
        margin: 2%;
        display: flex;
        background-color: white;
        border-radius: 6px;
        border: 1.5px solid rgb(66, 66, 66);
        flex-wrap: wrap;

    }

    .ArticleContainer {
        width: 100%;
        height: auto;
        display: block;
        justify-content: center;
        text-align: center;
        padding-top: 8%;
        border-top: 2px rgb(129, 129, 129) solid;

    }

    .LeftContainer {
        width: 50%;
        height: auto;
        padding-right: 8%;
        display: block;
        justify-content: center;
        text-align: center;
        border-right: 2px rgb(129, 129, 129) solid;
    }

    .Logo {
        justify-content: center;
        width: 65%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .Description {

        line-height: 1.8;
        letter-spacing: 0.3 px;
        font-size: 1.3rem;
        color: #272727;
        padding-top: 1rem;
        text-align: center;
        padding-bottom: 0.5rem;

    }

    .ParcelContainer {
        width: 50%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .Parcel {
        width: 60%;
        height: auto;
        vertical-align: middle;
        object-fit: contain;
        margin-left: 8%;

    }


    .RightContainer {
        position: relative;
        width: 50%;
        height: 500px;
        max-height: 500px;
        padding-left: 7%;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .ProductBox {
        width: 100%;
        height: 100%;
    }

    .BoxPlaceholder {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    }
    .Spinner {
        margin: 0 auto;
    }

    .StartButton {
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        height: auto;
        /*     letter-spacing: 0.5px;
 */
        font-size: 1.7rem;
        font-weight: bold;
        background-color: white;
        color: rgb(250, 155, 13);
        border: 2px solid rgb(250, 155, 13);
        border-radius: 10px;
        cursor: pointer;
        transition: box-shadow 150ms ease-in-out;
        justify-content: center;
        padding: 0.3rem;



    }


    .StartButton:focus {
        outline: none;

    }

    .StartButton:active {
        transform: scale(1.0)
    }

    .StartButton:hover {
        background-color: rgb(255, 255, 255);
        box-shadow: 0 2px 2px 1px rgba(255, 145, 0, 0.404), 0 2px 2px 1px rgba(255, 145, 0, 0.404)
    }

    .products {
        width: 80%;
        max-width: 800px;
        display: block;
        margin: 3% auto;
    }

    .product-card {
        padding: 5%;
        display: flex;
        border: 1px solid;
        margin: 1px;
        border-radius: 20px;
        border-color: #C0C0C0;
        background: white;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

    }

    .product-image img {
        margin-top: auto;
        border-right: 4px solid;
        border-bottom: none;
        border-color: #C0C0C0;
        padding: 5%;
        width: 100%;
    }

    .product-description {
        margin-left: 5%;
        flex-grow: 2;
    }

    .product-info h4 {
        text-align: left;
        font-weight: bold;
    }

    .product-button {
        margin-top: 10%;
        border-radius: 20px;
        font-weight: bold;
        white-space: wrap;
    }
}

@media screen and (max-width: 990px) {
    .MainContainer {
        width: 95%;
        height: auto;
        padding-top: 5%;
        padding-bottom: 5%;
        padding-left: 10%;
        padding-right: 10%;
        margin: 2.5%;
        display: block;
        background-color: white;
        border-radius: 6px;
        border: 1.5px solid rgb(66, 66, 66);
        box-sizing: border-box;
    }

    .MainContainer2 {
        width: auto;
        height: auto;
        min-height: 650px;
        padding: 3% 5%;
        margin: 2%;
        background-color: white;
        border-radius: 6px;
        border: 1.5px solid rgb(66, 66, 66);

    }

    .Logo2 {
        justify-content: center;
        width: 50%;
        height: auto;
        display: float;
        margin-left: auto;
        margin-right: auto;
        display: none;
    }

    .LeftContainer {
        width: 100%;
        height: auto;
        display: block;
        justify-content: center;
        text-align: center;
        /*         border-bottom: 2px rgb(129, 129, 129) solid;
 */
        padding-bottom: 5%;
        /* padding: 0 5% 5% 0; */

    }

    .Logo {
        justify-content: center;
        width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .Description {

        line-height: 1.5;
        letter-spacing: 0.25 px;
        font-size: 18px;
        color: #272727;
        padding-top: 5%;
        text-align: center;
        padding-bottom: 2%;

    }

    .ParcelContainer {
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;

    }

    .Parcel {
        width: 90%;
        height: auto;
        vertical-align: middle;
        object-fit: contain;

    }


    .RightContainer {
        margin-top: 3rem;
        position: relative;
        width: 100%;
        height: 500px;
        text-align: center;
    }

    .ProductBox {
        width: 100%;
        height: 100%;
    }

    .Spinner {
        margin: 0 auto;
    }

    .StartButton {
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        width: 80%;
        height: auto;
        /*         letter-spacing: 0.5px;
 */
        font-size: 1.7rem;
        font-weight: bold;
        background-color: white;
        color: rgb(250, 155, 13);
        border: 2px solid rgb(250, 155, 13);
        border-radius: 10px;
        cursor: pointer;
        transition: box-shadow 150ms ease-in-out;
        justify-content: center;



    }

    .StartButton:focus {
        outline: none;

    }

    .StartButton:active {
        transform: scale(1.0)
    }

    .StartButton:hover {
        background-color: rgb(255, 255, 255);
        box-shadow: 0 2px 2px 1px rgba(255, 145, 0, 0.404), 0 2px 2px 1px rgba(255, 145, 0, 0.404)
    }

    .products {
        width: 80%;
        max-width: 800px;
        display: block;
        margin: auto;
    }

    .product-card {
        padding: 5%;
        display: flex;
        border: 1px solid;
        margin: 1px;
        border-radius: 20px;
        border-color: #C0C0C0;
        background: white;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

    }

    .product-image img {
        margin-top: auto;
        border-right: none;
        border-bottom: 4px solid;
        border-color: #C0C0C0;
        padding: 5%;
        width: 100%;
    }

    .product-description {
        margin-left: 5%;
        flex-grow: 2;
    }

    .product-info h4 {
        text-align: left;
        font-weight: bold;
    }

    .product-button {
        margin-top: 10%;
        border-radius: 20px;
        font-weight: bold;
        white-space: wrap;
    }
}
.Box {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
    /*     box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.452), -2px 3px 5px rgba(0, 0, 0, 0.452) ;
 */
}

.boxPlaceholder {
position: absolute;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
}
.Box2 {
    margin-top: 4%;
    display: block;

    

}

.Imagebox {
    width: 100%;
    height: 85%;
    justify-content: center;
}

.imagePlaceHolder {
width: 70%;
min-height: 85%;
border-radius: 10px;
animation: pulse 1s infinite ease-in-out;
}
.buttonPlaceHolder {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    width: 80%;
    min-height: 3.5rem;
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    justify-content: center;
    padding: 0.3rem;
    margin-top: 2rem;
    animation: pulse 1s infinite ease-in-out;
}
.Image {
    width: 100%;
    height: 100%;
    object-fit: contain;
   
}

.ImageLink {
    width: 100%; 
    height: 100%;
}

.errorProduct {
    font-size: 2rem;
    font-weight: bold;
    color: black;

}

.price1 {
    margin-right: 13%;
}

.button1 {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    width: 80%;
    height: auto;
    /* letter-spacing: 0.5px; */
    font-size: 1.7rem;
    font-weight: bold;
    background-color: white;
    color: rgb(250, 155, 13);
    border: 2px solid rgb(250, 155, 13);
    border-radius: 10px;
    cursor: pointer;
    transition: box-shadow 150ms ease-in-out;
    justify-content: center;
    padding: 0.3rem;
    margin-top: 2rem;
}

.button1:hover {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 2px 0 rgba(255, 145, 0, 0.404), 0 1px 3px 1px rgba(255, 145, 0, 0.438)
}

@media screen and (max-width: 990px) {
    .Imagebox {
        width: 100%;
        height: 75%;
        justify-content: center;
    }
    
    .imagePlaceHolder {
    width: 90%;
    min-height: 75%;
    border-radius: 10px;
    animation: pulse 1s infinite ease-in-out;
    }
    
    .button1 {
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        width: 80%;
        height: auto;
        /* letter-spacing: 0.5px; */
        font-size: 1.5rem;
        font-weight: bold;
        background-color: white;
        color: rgb(250, 155, 13);
        border: 2px solid rgb(250, 155, 13);
        border-radius: 10px;
        cursor: pointer;
        transition: box-shadow 150ms ease-in-out;
        justify-content: center;
        padding: 0.3rem;
        margin-top: 2rem;
    }

    .buttonPlaceHolder {
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        width: 80%;
        min-height: 5rem;
        font-size: 1.5rem;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
        justify-content: center;
        padding: 0.3rem;
        margin-top: 2rem;
        animation: pulse 1s infinite ease-in-out;
    }
}
    

@keyframes pulse {
    0% {
      background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
      background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
      background-color: rgba(165, 165, 165, 0.1);
    }
  }

@keyframes fadeIn {
    0% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 450px) {
    .button1 {
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        width: 80%;
        height: auto;
        /*         letter-spacing: 0.5px;
 */
        font-size: 1.7rem;
        font-weight: bold;
        background-color: white;
        color: rgb(250, 155, 13);
        border: 2px solid rgb(250, 155, 13);
        border-radius: 10px;
        cursor: pointer;
        transition: box-shadow 150ms ease-in-out;
        justify-content: center;
    }


}
.box {
    width: 95%;
    height: auto;
    padding: 3% 5%;
    margin: 2%;
    display: block;
    background-color: white;
    border-radius: 6px;
    border: 1.5px solid rgb(66, 66, 66);
}
