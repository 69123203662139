
@import url('https://fonts.googleapis.com/css?family=Roboto:300&display=swap');

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 900px;
  background-color: #ff8b01ff;
  /* background-image: linear-gradient(360deg, rgba(255, 167, 45, 1) 0%, rgba(255,155,3,1) 40%, rgba(255,143,3,1) 100%);  */
  background-repeat: no-repeat;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
