.Box {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
    /*     box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.452), -2px 3px 5px rgba(0, 0, 0, 0.452) ;
 */
}

.boxPlaceholder {
position: absolute;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
}
.Box2 {
    margin-top: 4%;
    display: block;

    

}

.Imagebox {
    width: 100%;
    height: 85%;
    justify-content: center;
}

.imagePlaceHolder {
width: 70%;
min-height: 85%;
border-radius: 10px;
animation: pulse 1s infinite ease-in-out;
}
.buttonPlaceHolder {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    width: 80%;
    min-height: 3.5rem;
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    justify-content: center;
    padding: 0.3rem;
    margin-top: 2rem;
    animation: pulse 1s infinite ease-in-out;
}
.Image {
    width: 100%;
    height: 100%;
    object-fit: contain;
   
}

.ImageLink {
    width: 100%; 
    height: 100%;
}

.errorProduct {
    font-size: 2rem;
    font-weight: bold;
    color: black;

}

.price1 {
    margin-right: 13%;
}

.button1 {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    width: 80%;
    height: auto;
    /* letter-spacing: 0.5px; */
    font-size: 1.7rem;
    font-weight: bold;
    background-color: white;
    color: rgb(250, 155, 13);
    border: 2px solid rgb(250, 155, 13);
    border-radius: 10px;
    cursor: pointer;
    transition: box-shadow 150ms ease-in-out;
    justify-content: center;
    padding: 0.3rem;
    margin-top: 2rem;
}

.button1:hover {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 2px 0 rgba(255, 145, 0, 0.404), 0 1px 3px 1px rgba(255, 145, 0, 0.438)
}

@media screen and (max-width: 990px) {
    .Imagebox {
        width: 100%;
        height: 75%;
        justify-content: center;
    }
    
    .imagePlaceHolder {
    width: 90%;
    min-height: 75%;
    border-radius: 10px;
    animation: pulse 1s infinite ease-in-out;
    }
    
    .button1 {
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        width: 80%;
        height: auto;
        /* letter-spacing: 0.5px; */
        font-size: 1.5rem;
        font-weight: bold;
        background-color: white;
        color: rgb(250, 155, 13);
        border: 2px solid rgb(250, 155, 13);
        border-radius: 10px;
        cursor: pointer;
        transition: box-shadow 150ms ease-in-out;
        justify-content: center;
        padding: 0.3rem;
        margin-top: 2rem;
    }

    .buttonPlaceHolder {
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        width: 80%;
        min-height: 5rem;
        font-size: 1.5rem;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
        justify-content: center;
        padding: 0.3rem;
        margin-top: 2rem;
        animation: pulse 1s infinite ease-in-out;
    }
}
    

@keyframes pulse {
    0% {
      background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
      background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
      background-color: rgba(165, 165, 165, 0.1);
    }
  }

@keyframes fadeIn {
    0% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 450px) {
    .button1 {
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        width: 80%;
        height: auto;
        /*         letter-spacing: 0.5px;
 */
        font-size: 1.7rem;
        font-weight: bold;
        background-color: white;
        color: rgb(250, 155, 13);
        border: 2px solid rgb(250, 155, 13);
        border-radius: 10px;
        cursor: pointer;
        transition: box-shadow 150ms ease-in-out;
        justify-content: center;
    }


}